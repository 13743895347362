.main-detail{
width: 100%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
margin: auto;
}

.product-image img{
    width: 250px;
    height: 350px;
    object-fit: cover;
    background-repeat: no-repeat;
}

.product__details-price img{
 width: 25px;
 font-weight: bold;
}

.products__details h2{
    font-size: 1.5rem;
}

.products__details h2,
.products__details p{
    text-align: center;
    margin: 5px 0;
    line-height: 30px;
}


.product-info {
    width: 300px;
    margin: auto;
    align-items: center;
}

@media only screen and (max-width: 275px){
    .product-image img{
        width: 200px;
        height: 300px;

    }
    
    .product__details-price img{
     width: 25px;
     font-weight: bold;
    }


    
    .products__details h2{
        font-size: 1.4rem;
    }

    .products__details p{
        font-size: 1rem;
    }

    .products__details span{
        font-size: .8rem;
    }
    
    
    .product-info {
        width: 300px;
    }
}

@media only screen and (max-width: 275px){
    .product-image img{
        width: 150px;
        height: 220px;
    }
    .products__details p{
        font-size: .8rem;
        line-height: 20px;
    }
}

@media only screen and (max-width: 275px){
    .product-image img{
        width: 150px;
        height: 220px;
    }
}