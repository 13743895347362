.product__item {
  cursor: pointer;
  border-radius: 5px;
  width: 220px;
  box-shadow: 1px 0px 12px -4px rgba(189, 3, 3, 0.88);
  -webkit-box-shadow: 0px 1px 11px -3px rgba(24, 24, 23, 0.88);
  -moz-box-shadow: 1px 0px 12px -4px rgba(23, 22, 22, 0.88);
  background: #fff;
  margin-bottom: 20px;
}

.product__info h3 {
  font-size: 1.1rem;
  font-weight: 600;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product__info {
  padding: 3px;
}

.product__info span {
  display: flex;
  justify-content: center;
  align-items: center;
}
.product__card-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
}

.product__info h3 a {
  color: black;
}

.product__card-bottom .price img {
  width: 17px;
}

.product__img img {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  height: 280px;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 1px solid var(--card-bg);
}

.product__card-bottom {
  display: flex;
}

.product__card-bottom span i {
  font-size: 1.2rem;
  padding: 5px;
  background: var(--primary-color);
  color: #fff;
  /* cursor: pointer; */
  border-radius: 50px;
}

.info {
  height: 130px;
  position: relative;
}

.info .product__card-bottom {
  width: 100%;
  position: absolute;
  bottom: 0;
}

@media only screen and (max-width: 235px) {
  .product__item {
    width: 200px;
  }

  .product__img img {
    width: 100%;
    height: 200px;
  }

  .product__name a{
    font-size: 1rem;
  }

  .product__info span{
    font-size: .8rem;
  }
}

