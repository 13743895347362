

.home {

  background-image: url('../assets//images/bonanza2.jpg');
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
  padding: 25px 0 20px 0;
  display: flex;
  margin: auto;
}

.products {
  cursor: pointer;
  width: 80%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
  border-radius: 10px;
  box-shadow: 0px 9px 40px -9px rgba(0, 0, 0, 0.79);
  -webkit-box-shadow: 0px 9px 40px -9px rgba(0, 0, 0, 0.79);
  -moz-box-shadow: 0px 9px 40px -9px rgba(0, 0, 0, 0.79);
  background: rgb(195, 177, 212);
  background: linear-gradient(
    90deg,
    rgba(195, 177, 212, 0.528) 10%,
    rgba(74, 26, 185, 0.422) 48%,
    rgba(223, 132, 181, 0.267) 94%
  );
}

.products img {
  border-radius: 5px;
  width: 100%;
  height: 200px;
  object-fit: cover;
  background-position: center;
}

.products h3 {
  text-align: center;
  margin-top: 10px;
  font-size: 1.4rem;
  color: #fff;
}

/* .product-details{
    align-items: center;
    justify-content: center;
} */
