.filtered__items{
    background-image: url('../assets//images/bonanza.jpg');
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    object-fit: cover;
}

.filtered__items-title{
    color: #fff;
    font-weight: 600;
    font-size: 2rem;
}

@media only screen and (max-width: 992px){
    .filtered__items-title{
        font-size: 1.4rem;
    }
}

@media only screen and (max-width: 136px){
    .filtered__items-title{
        font-size: 1rem;
    }
}